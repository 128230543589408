<template>
    <div
            class="active-sequence-step-back-btn"
            :class="[
                isInjector && 'active-sequence-step-back-btn--injector',
                isFirstStep || getActiveStep.disableBackButton ? 'disabled-step-back' : ''
            ]"
            @click="goBack"
    >
        &lt; Шаг назад
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: 'ActiveSequenceStepBack',
        methods: {
            goBack() {
                this.$store.dispatch('sequences_store/goBack')
            },
        },
        computed: {
            ...mapGetters('sequences_store', ['getActiveStep','getActiveStepId', 'getActiveStepsIds']),
            isFirstStep() {
                return this.getActiveStepsIds?.indexOf(this.getActiveStepId) === 0
            },
            isInjector() {
                return this.$router.currentRoute.name === 'Injector'
            },
        }

    }
</script>

<style lang="scss" scoped>
    .active-sequence-step-back-btn {
        padding: 10px 56px;
        background-color: #d5f6ff;
        position: absolute;
        bottom: 15%;
        width: 198px;
        left: 2%;
        z-index: 30;
        min-width: 150px;
        border-radius: 3px;
        border: 1px solid #384A57;
        text-align: center;
        cursor: pointer;

        &--injector {
            bottom: 25%;
        }
    }

    .disabled-step-back {
        pointer-events: none;
        background-color: #cccccc;
        border: 1px solid #9b9b9b;
    }
</style>